import React, { useContext } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import classNames from "classnames";

import * as paginationStyles from "../styles/components/PaginationFooter.module.scss";
import { ThemeContext } from "./ThemeProvider";

type PaginationFooterProps = {
  currentPage: number;
  numPages: number;
}

const getPreviousLink = (isFirst: boolean, prevPage: string) => {
  if (!isFirst) {
    return ( 
      <Link to={`/blog/${prevPage}`} rel="prev"> 
        Previous Page
      </Link>
    );
  } else {
    return (
      <span className={paginationStyles.disabled}>
        <button>Previous Page</button>
      </span>
    )
  }
}

const getNextLink = (isLast: boolean, nextPage: string) => {
  if (!isLast) {
    return (
      <Link to={`/blog/${nextPage}`} rel="next"> 
        Next Page
      </Link>
    );
  } else {
    return (
      <span className={paginationStyles.disabled}>
        <button>Next Page</button>
      </span>
    );
  }
}

const PaginationFooter = ({ currentPage, numPages }: PaginationFooterProps) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  const { isDarkMode } = useContext(ThemeContext);

  const lineSeparatorClass = classNames({
    [paginationStyles.lineSeparator]: true,
    [paginationStyles.darkTheme]: isDarkMode
  });

  return (
    <div className={paginationStyles.footerContainer}>
      <div className={lineSeparatorClass}></div>
      <div className={paginationStyles.paginationContainer}>
        {getPreviousLink(isFirst, prevPage)}
        {getNextLink(isLast, nextPage)}
      </div>
    </div>
  );
};

export default PaginationFooter;
